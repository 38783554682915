import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { ChevronDoubleLeftFilled, ChevronLeftFilled, ChevronRightFilled, ChevronDoubleRightFilled } from '@fluentui/react-icons';
import { useTheme, Typography } from '@mui/material';
import { memo } from 'react';
import { Box } from '../../Box.js';
import { Button } from '../../button/Button.js';

const PageSelector = ({ currentPage, totalPages, pagesToShow = 5, onSelect, hidePages }) => {
    const theme = useTheme();
    const moveToPage = (page) => onSelect && onSelect(page);
    const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1).filter((i) => i === 1 ||
        i === totalPages ||
        (i >= currentPage - pagesToShow && i <= currentPage + pagesToShow));
    if (totalPages <= 1)
        return jsx(Fragment, {});
    return (jsxs(Box, { background: 'none', direction: 'row', alignItems: 'center', justifyContent: 'center', "data-testid": 'page-selector', children: [jsx(Box, { background: 'none', direction: 'row', justifyContent: 'end', children: currentPage > 1 && (jsxs(Fragment, { children: [!hidePages && (jsx(Button, { variant: 'text', shape: 'square', onClick: () => moveToPage(1), children: jsx(ChevronDoubleLeftFilled, {}) })), jsx(Button, { variant: 'text', shape: 'square', onClick: () => moveToPage(currentPage - 1), "data-testid": 'prev-page', children: jsx(ChevronLeftFilled, {}) })] })) }), jsx(Box, { background: 'none', direction: 'row', alignItems: 'center', justifyContent: 'center', style: { flex: '0 1 min-content' }, children: !hidePages && pagesArray.map((page) => (jsx(Button, { variant: 'text', onClick: () => moveToPage(page), style: { minWidth: '1.5rem' }, children: jsx(Typography, { variant: 'body1', sx: currentPage === page
                            ? {
                                color: theme.palette.info.dark,
                                textDecoration: `${theme.palette.primary.main} underline 0.125rem`,
                                textUnderlineOffset: '0.5rem',
                            }
                            : {}, children: page.toString().padStart(2, '0') }) }, page))) }), jsx(Box, { background: 'none', direction: 'row', justifyContent: 'start', children: currentPage < totalPages && (jsxs(Fragment, { children: [jsx(Button, { variant: 'text', color: 'primary', shape: 'square', onClick: () => moveToPage(currentPage + 1), "data-testid": 'next-page', children: jsx(ChevronRightFilled, {}) }), !hidePages && (jsx(Button, { variant: 'text', color: 'primary', shape: 'square', onClick: () => moveToPage(totalPages), children: jsx(ChevronDoubleRightFilled, {}) }))] })) })] }));
};
const MemoizedPageSelector = memo(PageSelector);

export { MemoizedPageSelector, PageSelector };
