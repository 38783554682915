import { useRef, useState, useCallback } from 'react';
import 'react/jsx-runtime';
import '@fluentui/react-icons';
import '@mui/material';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import '../config.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useSaveItemDetailsMutation } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import { transformSyncFusionPropertiesToJson } from '../components/item/ItemBusinessTypeUtil.js';
import { useItemPropertiesMenuItem } from './useItemPropertiesMenuItem.js';
import 'react-router-dom';
import 'lodash';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import '../components/chip/ItemManagementStatusChip.js';
import '../components/chip/PerspectiveClassChip.js';
import '@dnd-kit/utilities';
import '@mui/lab';
import '../components/dialog/components/item/BusinessTypeField.js';
import '@syncfusion/ej2-react-calendars';
import { getParentNameFromObject } from '../components/item/ItemPropertiesUtil.js';
import '../components/item/utils.js';
import '../components/dialog/components/item/CustomTypeField.js';
import '../components/dialog/components/item/PerspectiveClassField.js';
import '../components/OptionMultiSelect.js';
import '../components/SortableOptionList.js';
import '@mui/material/Breadcrumbs';
import '@mui/material/Menu';
import '@mui/material/MenuItem';
import '../components/grid/components/PageSizeDropdown.js';
import '../components/grid/components/PageSelector.js';
import '@mui/system';
import '@syncfusion/ej2-data';
import '@syncfusion/ej2-react-grids';
import '../components/grid/templates/CommentTemplate.js';
import '../components/grid/templates/ItemSummaryTemplate.js';
import '../components/grid/templates/PagerTemplate.js';
import '../components/grid/templates/RetentionClassTemplate.js';
import '../components/grid/templates/SummaryTemplate.js';
import '../components/grid/templates/DisposalStatusTemplate.js';
import '../components/grid/ItemsGrid.js';
import '../components/item/ItemBusinessTypePanel.js';
import { Dialog } from '../components/item/ItemGovernancePanel.js';
import '../components/item/type/ItemTypeEmailPanel.js';
import '../components/ItemPanel.js';
import '../components/DisposalRequestProgressIndicatorStatus.js';
import '../components/FileViewer.js';
import '../components/ItemPropertiesPanel.js';
import '../components/ItemSummaryTitle.js';
import 'react-zendesk';
import '../components/OptionSelect.js';
import '../components/CustomTypeTreeGrid.js';
import { dateTimeRegex } from '../components/item/template/PropertyValueTemplate.js';
import { useSearch } from './useSearch.js';

var Field;
(function (Field) {
    Field["PerspectiveClass"] = "PerspectiveClass";
    Field["Boolean"] = "Boolean";
    Field["BusinessType"] = "BusinessType";
    Field["BusinessClasses"] = "BusinessClasses";
    Field["Classifications"] = "Classifications";
})(Field || (Field = {}));
const useItemPropertiesPanel = ({ item, hasEditPermission, rowSelected, formDetails, selectedTab, itemTypeName, propLinksData, businessTypeName, businessTypeTreeGridData, }) => {
    // HOOKS
    const gridRef = useRef(null);
    const [dialogDetails, setDialogProps] = useState({
        open: false,
    });
    const [saveItemDetails, { isLoading: isSaving }] = useSaveItemDetailsMutation();
    // CUSTOM HOOKS
    // this is for gettng the dropdown menu items
    const { onChange, menuItem, treeGridData } = useItemPropertiesMenuItem({
        item,
        businessTypeTreeGridData,
        businessTypeName,
        itemTypeName,
        gridRef,
        selectedTab,
        propLinksData,
    });
    // create a custom search function for the tree grid.
    const { handleSearch } = useSearch({
        treeGridData,
        gridRef,
        menuItem,
    });
    const setDialogDetails = ({ open, title, dialog, fieldLabel, propertyName, propertyValue, }) => setDialogProps({
        open,
        title,
        dialog,
        fieldLabel,
        propertyName,
        propertyValue,
    });
    const handleActionComplete = useCallback(async (args) => {
        if (args.type === 'save') {
            // Just a workaround to show the template values after inline edit
            gridRef.current.refreshColumns();
            if (args?.previousData === args?.data?.value ||
                (dateTimeRegex.test(args?.previousData) &&
                    args?.previousData.substring(0, 10) === args?.data?.value))
                return;
            const businessObject = formDetails?.BusinessObject;
            // workaround to fix the inline editing issue.
            const parentName = getParentNameFromObject(args?.data);
            const storedGridData = JSON.parse(localStorage.getItem('treeGridResultProperties'));
            const cellValue = gridRef.current?.dataSource.find((x) => x.propDef.Name === parentName);
            const updatedTreeGridData = storedGridData?.map((x) => x.propDef.Name === parentName ? cellValue ?? null : x);
            // Get all the business type-related properties in the tree grid
            const businessTypeGridData = Object.keys(businessObject)
                .map((key) => updatedTreeGridData.find((x) => x.propDef.Name === key))
                .filter((item) => item);
            const treeGridDataJSON = transformSyncFusionPropertiesToJson(businessTypeGridData, businessObject);
            // format formData object
            const formData = {
                BusinessObject: {
                    ...treeGridDataJSON,
                },
                RepositoryId: item?.RepositoryId,
            };
            // format the request object
            const requestObj = {
                ID: item?.ID,
                TypeDefId: item?.TypeDefId,
                formDetails: formData,
            };
            try {
                gridRef.current.showSpinner();
                // save the values
                setTimeout(async () => {
                    await saveItemDetails(requestObj);
                }, 1000);
            }
            catch (error) {
                // Handle error
                console.error('Error on updating the item details on the properties page:', error);
                gridRef.current.hideSpinner();
            }
        }
    }, [gridRef, formDetails]);
    const handleRowSelection = (rowData) => {
        if (rowData) {
            // send rowData string to another component
            rowSelected(rowData);
        }
    };
    const handleRowSelecting = useCallback((args) => {
        const parentName = getParentNameFromObject(args.data);
        const businessObject = formDetails?.BusinessObject;
        const _selectedRow = args.data;
        const _isAllowEditing = (!!_selectedRow &&
            !_selectedRow?.isCollection &&
            !_selectedRow?.childRecords?.length &&
            hasEditPermission &&
            parentName in businessObject) ??
            false;
        // this is to prevent inline editing when the field is a collection
        gridRef.current.editSettings.allowEditing = _isAllowEditing;
    }, [formDetails, hasEditPermission]);
    const handleRecordDoubleClick = (args) => {
        const { rowData } = args;
        if ([
            Field.BusinessClasses,
            Field.Classifications,
            Field.BusinessType,
        ].includes(rowData?.propDef?.Name)) {
            let title;
            let fieldLabel;
            let dialog;
            if (rowData?.propDef?.Name === Field.BusinessType) {
                dialog = Dialog.BUSINESS_TYPE;
                title = 'Edit Business Type';
                fieldLabel = 'Business Type';
            }
            if (rowData?.propDef?.Name === Field.BusinessClasses) {
                dialog = Dialog.PERSPECTIVE;
                title = 'Edit Perspective Classes';
                fieldLabel = 'Perspective Classes';
            }
            if (rowData?.propDef?.Name === Field.Classifications) {
                dialog = Dialog.RETENTION;
                title = 'Edit Retention Classes';
                fieldLabel = 'Retention Classes';
            }
            setDialogDetails({
                title,
                dialog,
                fieldLabel,
                open: true,
                propertyName: rowData?.propDef,
                propertyValue: rowData?.value?.value ?? rowData?.value,
            });
        }
    };
    const handleDialogClose = () => {
        setDialogDetails({
            open: false,
        });
    };
    const handleDialogSave = async (value, propertyDetails) => {
        // this is to prevent from calling the API again.
        if (isSaving)
            return;
        gridRef.current.showSpinner();
        // Format the selected value(s) from the dialog.
        // Get only the needed IDs (ID and TypeDefId)
        const formattedDialogValues = Array.isArray(value)
            ? value?.length
                ? value?.map(({ ID, TypeDefId }) => ({
                    ID,
                    TypeDefId,
                }))
                : null
            : value;
        // Format form details
        // propertyDetails returns the property details based on the selected dialog
        // BusinessClasses property = Perspective Classes Dialog
        // Classifications property = Retention Classes Dialog
        const formDetails = {
            [propertyDetails?.Name]: formattedDialogValues,
        };
        const { ID, TypeDefId } = item;
        // request object
        const requestObj = {
            ID,
            TypeDefId,
            formDetails,
        };
        try {
            setTimeout(async () => {
                await saveItemDetails(requestObj);
            }, 1000);
        }
        catch (error) {
            // Handle error
            console.error('Error on updating the item details:', error);
            gridRef.current.hideSpinner();
        }
        setDialogDetails({ open: false });
    };
    return {
        gridRef,
        menuItem,
        formDetails,
        treeGridData,
        dialogDetails,
        hasEditPermission,
        onChange,
        handleSearch,
        handleDialogSave,
        handleDialogClose,
        handleRowSelection,
        handleRowSelecting,
        handleActionComplete,
        handleRecordDoubleClick,
    };
};

export { Field, useItemPropertiesPanel };
