import { useState, useEffect } from 'react';
import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { publicClientApplication } from '../../auth/AuthProvider.js';
import { loginRequest } from '../../auth/config.js';
import 'react/jsx-runtime';
import { config } from '../../config.js';
import '@azure/msal-react';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { dateTimeToUTC } from '../../util/date-time-to-utc.js';

class SnapshotsAdaptor extends ODataV4Adaptor {
    constructor() {
        super();
    }
    processQuery(dm, query) {
        let original = super.processQuery.apply(this, arguments);
        //removes the tolower function from the query as it is not supported by the OData implementation
        if (original && typeof original.url === 'string') {
            original = { ...original, url: original.url.replace(/tolower/g, '') };
        }
        return original;
    }
    processResponse() {
        // calling base class processResponse function
        var original = super.processResponse.apply(this, arguments);
        const formattedData = Array.isArray(original.result)
            ? original.result.map((item) => {
                item.DisplayName = dateTimeToUTC(item?.DateCaptured);
                return item;
            })
            : null;
        return {
            result: formattedData,
            count: original.count,
        };
    }
}
const useSnapshotsData = ({ gridData, id, }) => {
    const [dataManager, setDataManager] = useState();
    //could also add token as a property rather than acquiring it here
    const acquireToken = async () => {
        const accounts = publicClientApplication.getAllAccounts();
        publicClientApplication.setActiveAccount(accounts[0]);
        const silentRequest = {
            ...loginRequest,
            account: accounts[0],
        };
        const authResult = await publicClientApplication.acquireTokenSilent(silentRequest);
        return authResult.accessToken;
    };
    async function fetchData() {
        try {
            const token = await acquireToken();
            // Item Collection URL - to be replaced
            const URL = `${config.API_BASE_URL}/Item('${id}')/SnapshotHistory`;
            const data = new DataManager({
                url: URL,
                adaptor: new SnapshotsAdaptor(),
                headers: [{ Authorization: `Bearer ${token}` }],
            });
            if (data['@odata.count'] === 0)
                setDataManager(null);
            else
                setDataManager(data);
        }
        catch (error) {
            setDataManager(null);
        }
    }
    useEffect(() => {
        if (!gridData?.length)
            fetchData();
    }, [gridData]);
    return { dataManager };
};

export { useSnapshotsData as default };
