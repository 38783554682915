import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { ColumnEdit20Regular, ChevronDownFilled } from '@fluentui/react-icons';
import { Typography } from '@mui/material';
import { DataUtil } from '@syncfusion/ej2-data';
import { GridComponent, Inject, Resize, Sort, Freeze, Page, Reorder, ColumnChooser, Filter, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import { useRef, useState, useEffect, useMemo } from 'react';
import { DisposalRequestStatus, DisposalRequestResponseStatusCaption, DisposalRequestResponseStatus } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { Box } from '../Box.js';
import { DisposalRequestApprovers } from '../DisposalRequestApprovers.js';
import { Button } from '../button/Button.js';
import '../../config.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import '../chip/ItemManagementStatusChip.js';
import '../chip/PerspectiveClassChip.js';
import { RetentionClassChip } from '../chip/RetentionClassChip.js';
import { UserChip } from '../chip/UserChip.js';
import { DetailDialog } from '../dialog/DetailDialog.js';
import useData from './DataManagerHook.js';
import { OpenInContextMenu } from './components/OpenInContextMenu.js';
import { CommentTemplate } from './templates/CommentTemplate.js';
import './templates/ItemSummaryTemplate.js';
import { PagerTemplate } from './templates/PagerTemplate.js';
import { ResponseCommentsTemplate } from './templates/ResponseCommentsTemplate.js';
import './templates/RetentionClassTemplate.js';
import { SummaryTemplate } from './templates/SummaryTemplate.js';
import './templates/DisposalStatusTemplate.js';
import { DetailTabDialog } from '../dialog/DetailTabDialog.js';

const DisposalRequestGrid = ({ user, data, filter, onOpen, onSelect, onRead, pageSize = 30, pageSizes = [30, 60, 100], title, adminMode = false, }) => {
    const gridRef = useRef(null);
    const [selected, setSelected] = useState();
    const { dataManager } = useData({
        status: filter,
        user,
        gridData: data,
        adminMode,
    });
    const key = Math.random();
    const filterSettings = {
        type: 'Menu',
    };
    const [comment, setComment] = useState(null);
    const [responseCommentTabs, setResponseCommentTabs] = useState(null);
    const [contextAnchor, setContextAnchor] = useState(null);
    const [contextRequest, setContextRequest] = useState();
    const dateFormat = { type: 'date', format: 'dd MMM yyy' };
    //allow for multiple select in case it is used in future
    const handleSelect = () => {
        const _selected = gridRef?.current?.getSelectedRecords();
        setSelected(_selected);
        onSelect && onSelect(_selected);
    };
    useEffect(handleSelect, []);
    const showColumnChooser = () => {
        if (gridRef?.current) {
            gridRef.current.openColumnChooser(0, 0);
        }
    };
    //TODO: Remove or add this pending business decision
    // const filterTemplate = (props) => {
    //   console.log('props', props);
    //   const handleTest = () => {
    //     if (gridRef?.current) {
    //       gridRef.current.clearFiltering();
    //       console.log('gridRef.current', gridRef.current);
    //       // gridRef.current.filterByColumn('CustomerID', 'equal', '');
    //     }
    //   };
    //   // dropdata.push('Clear');
    //   /** The enabled attributes will be added based on the column property. */
    //   return (
    //     // <DropDownListComponent
    //     //   enabled={props.column.allowFiltering}
    //     //   id={props.column.field}
    //     //   popupHeight='250px'
    //     //   dataSource={dropdata}
    //     //   change={onChange}
    //     // />
    //     <div onClick={handleTest}>Hello</div>
    //   );
    // };
    const gridData = DataUtil.parse.parseJson(JSON.stringify(data));
    const getSortingOptions = () => {
        switch (true) {
            case adminMode && filter === DisposalRequestStatus.New:
                return {
                    columns: [
                        {
                            field: 'DateCreatedInEnc',
                            direction: 'Descending',
                        },
                    ],
                };
            case adminMode && filter === DisposalRequestStatus.InApproval:
                return {
                    columns: [
                        {
                            field: 'DateRequested',
                            direction: 'Descending',
                        },
                    ],
                };
            case adminMode && filter === DisposalRequestStatus.Approved:
                return {
                    columns: [
                        {
                            field: 'DateRequested',
                            direction: 'Descending',
                        },
                    ],
                };
            case filter === DisposalRequestResponseStatus.Delegated ||
                filter === DisposalRequestResponseStatus.Approved ||
                filter === DisposalRequestResponseStatus.Pending ||
                filter === DisposalRequestResponseStatus.Rejected:
                return {
                    columns: [
                        {
                            field: 'DateRequested',
                            direction: 'Descending',
                        },
                    ],
                };
            default:
                return undefined;
        }
    };
    const sortingOptions = getSortingOptions();
    //column widths only work if they are numbers so they have been set to pixels
    const Grid = useMemo(() => !!gridData || !!dataManager ? (jsxs(GridComponent, { dataSource: gridData ?? dataManager, allowResizing: true, enablePersistence: true, allowSorting: true, allowReordering: true, frozenColumns: 1, enableStickyHeader: true, allowFiltering: true, rowSelected: handleSelect, sortSettings: sortingOptions, rowDeselected: handleSelect, showColumnChooser: true, width: '100%', height: '100%', 
        //need the id to maintain persisted data
        id: `DisposalRequestGrid${adminMode ? '_admin' : ''}_${filter}`, "data-testid": `DisposalRequestGrid${adminMode ? '_admin' : ''}_${filter}`, ref: gridRef, allowPaging: true, pageSettings: { pageSize, pageSizes }, pagerTemplate: (pagerData) => {
            const pagerDataWithTotalPages = {
                ...pagerData,
                totalPages: Math.ceil(pagerData.totalRecordsCount / pagerData.pageSize),
            };
            return (jsx(PagerTemplate, { pagerData: pagerDataWithTotalPages, grid: gridRef?.current, pageSizes: pageSizes }, key));
        }, filterSettings: filterSettings, children: [jsx(Inject, { services: [
                    Resize,
                    Sort,
                    Freeze,
                    Page,
                    Reorder,
                    ColumnChooser,
                    Filter,
                ] }), jsxs(ColumnsDirective, { children: [jsx(ColumnDirective, { field: 'Name', headerText: 'Disposal Request', template: (request) => {
                            let _information = request.RequestedBy &&
                                `Requested by ${request.RequestedBy?.DisplayName}`;
                            if (adminMode) {
                                if ([
                                    DisposalRequestStatus.InApproval,
                                    DisposalRequestStatus.Approved,
                                    DisposalRequestStatus.Rejected,
                                ].includes(filter)) ;
                                else {
                                    _information = undefined;
                                }
                            }
                            return (jsx(SummaryTemplate, { adminMode: adminMode, title: request.Name, disposalId: request.ID, information: _information, disposalRequestStatus: request.Status, isUnread: adminMode && !request.IsRead, isDestructionActioned: adminMode &&
                                    (filter === DisposalRequestStatus.InProgress ||
                                        filter === DisposalRequestStatus.Complete), onClick: () => onOpen && onOpen(request), onContextMenu: (e) => {
                                    setContextRequest(request);
                                    setContextAnchor(e.currentTarget);
                                } }));
                        }, width: 400, minWidth: 200, allowReordering: false, showInColumnChooser: false }, 'Name'), adminMode && filter !== DisposalRequestStatus.Failed && (jsx(ColumnDirective, { field: 'RetentionClass.DisplayName', headerText: 'Retention Class', width: 280, minWidth: 180, template: (request) => (jsx(RetentionClassChip, { classification: request.RetentionClass, border: false, style: { marginTop: '-0.25rem' } })), allowEditing: false }, `RetentionClass_${filter}`)), adminMode && (filter === DisposalRequestStatus.InProgress ||
                        filter === DisposalRequestStatus.Complete) && (jsx(ColumnDirective, { field: 'ActionedBy', headerText: 'Actioned By', width: 280, minWidth: 200, allowFiltering: false, allowEditing: false, template: (request) => {
                            return (jsx(UserChip, { user: {
                                    name: request?.ActionedBy?.DisplayName,
                                }, border: false, style: {
                                    marginTop: '-0.25rem',
                                    marginLeft: '-0.25rem',
                                } }));
                        } }, 'ActionedBy')), adminMode && filter === DisposalRequestStatus.Complete && (jsx(ColumnDirective, { field: 'DateCompleted', headerText: 'Date Completed', width: 200, minWidth: 100, type: 'date', format: dateFormat, allowEditing: false, allowSorting: false, allowFiltering: false }, 'DateCompleted')), adminMode && filter === DisposalRequestStatus.New && (jsx(ColumnDirective, { field: 'DateCreatedInEnc', headerText: 'Date Created', width: 200, minWidth: 100, type: 'date', format: dateFormat, allowEditing: false }, `DateCreatedInEnc_${filter}`)), adminMode &&
                        [
                            DisposalRequestStatus.InApproval,
                            DisposalRequestStatus.Approved,
                            DisposalRequestStatus.Rejected,
                        ].includes(filter) && [
                        jsx(ColumnDirective, { type: 'string', field: 'Approvers.Status', headerText: 'Approvers', width: 280, minWidth: 200, allowFiltering: false, 
                            //TODO: Remove or add this pending business decision
                            // filterTemplate={filterTemplate}
                            template: (request) => (jsx(DisposalRequestApprovers, { responses: request.ApproverResponses?.value })), allowEditing: false, allowSorting: false }, `ApproverResponses_${filter}`),
                        jsx(ColumnDirective, { field: 'ApproverResponses.ResponseComments', headerText: 'Response Comments', width: 400, minWidth: 200, template: (request) => (jsx(ResponseCommentsTemplate, { responses: request.ApproverResponses?.value, onClick: (responses) => setResponseCommentTabs(responses?.map((response) => ({
                                    value: response.Approver?.DisplayName,
                                    children: response.ResponseComments,
                                }))) })), allowEditing: false, allowSorting: false, allowFiltering: false }, `ApproverResponseComments_${filter}`),
                        filter === DisposalRequestStatus.InApproval ? (jsx(ColumnDirective, { field: 'DateRequested', headerText: 'Date Of Request', width: 200, minWidth: 100, type: 'date', format: dateFormat, allowEditing: false }, `DateRequested_${filter}`)) : (jsx(ColumnDirective, { field: 'ApproverResponses.DateResponded', headerText: `Date ${DisposalRequestResponseStatusCaption[filter]}`, width: 200, minWidth: 100, valueAccessor: (field, request, column) => {
                                const date = request?.ApproverResponses?.value?.find((response) => response.Status ===
                                    request.Status)?.DateResponded;
                                return date ?? '-';
                            }, type: 'date', format: dateFormat, allowEditing: false, allowSorting: false, allowFiltering: false }, `ApproverResponses.DateResponded_${filter}`)),
                    ], !adminMode && (jsx(ColumnDirective, { field: 'DateRequested', headerText: 'Date Requested', width: 200, minWidth: 100, type: 'date', format: dateFormat, allowEditing: false }, `DateRequested_${filter}`)), !adminMode &&
                        filter !== DisposalRequestResponseStatus.Pending && [
                        jsx(ColumnDirective, { field: 'Response.DateResponded', headerText: `Date ${DisposalRequestResponseStatusCaption[filter]}`, width: 200, minWidth: 100, type: 'date', format: dateFormat, allowEditing: false, allowSorting: false, allowFiltering: false }, `Response.DateResponded_${filter}`),
                        jsx(ColumnDirective, { field: 'Response.ResponseComments', headerText: `${filter === DisposalRequestResponseStatus.Delegated
                                ? 'Delegation'
                                : 'Response'} Comments`, width: 400, minWidth: 200, template: (request) => (jsx(CommentTemplate, { variant: 'body1', comment: request.Response?.ResponseComments, onClick: setComment, numberOfLines: 4 })), allowEditing: false, allowSorting: false, allowFiltering: false }, 'Response.ResponseComments'),
                    ], filter === DisposalRequestResponseStatus.Delegated && (jsx(ColumnDirective, { field: 'DelegatedTo.DisplayName', headerText: 'Delegated To', width: 200, minWidth: 100, template: (request) => (jsx(UserChip, { user: {
                                name: request.DelegatedTo?.Approver?.DisplayName ?? '',
                            }, border: false, sx: { marginTop: '-0.25rem', marginLeft: '-0.25rem' } })), allowEditing: false, allowSorting: false, allowFiltering: false }, 'DelegatedTo.DisplayName')), filter === DisposalRequestResponseStatus.Pending && (jsx(ColumnDirective, { field: 'RequestComments', headerText: 'Request Comments', width: 400, minWidth: 200, template: (request) => (jsx(CommentTemplate, { comment: request.RequestComments, onClick: setComment, numberOfLines: 4 })), allowEditing: false }, 'RequestComments')), !adminMode &&
                        filter !== DisposalRequestResponseStatus.Delegated && (jsx(ColumnDirective, { field: 'RequestedBy.DisplayName', headerText: 'Requested By', width: 200, minWidth: 100, template: (request) => (jsx(UserChip, { user: {
                                name: request.RequestedBy?.DisplayName ?? '',
                            }, border: false, style: { marginTop: '-0.25rem', marginLeft: '-0.25rem' } })), allowEditing: false }, 'RequestedBy.DisplayName'))] })] }, key)) : (jsx(Fragment, {})), [gridData, dataManager, filter, sortingOptions]);
    return (
    //wrap with a box so that scrolling in the grid works
    jsxs(Box, { background: 'none', style: { minHeight: '25rem' }, children: [jsxs(Box, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', background: 'none', height: 6, children: [jsx(Typography, { variant: 'h1', children: title }), jsx(Box, { shrink: true, direction: 'row', gap: 'xlarge', alignItems: 'center', background: 'none', children: jsx(Button, { variant: 'text', startIcon: jsx(ColumnEdit20Regular, {}), endIcon: jsx(ChevronDownFilled, {}), onClick: showColumnChooser, children: "Change Columns" }) })] }), Grid, jsx(DetailDialog, { title: 'Comment', open: !!comment, onClose: () => setComment(null), children: comment }), jsx(DetailTabDialog, { title: 'Response Comments', tabs: responseCommentTabs ?? [], height: 40, width: 54, open: !!responseCommentTabs, onClose: () => setResponseCommentTabs(null) }), jsx(OpenInContextMenu, { anchor: contextAnchor, options: ['new tab', 'new window'], onClose: () => {
                    setContextAnchor(null);
                }, onOpen: (target) => {
                    setContextAnchor(null);
                    onOpen && contextRequest && onOpen(contextRequest, target);
                } })] }));
};

export { DisposalRequestGrid, DisposalRequestGrid as default };
