import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import '../auth/config.js';
import 'react/jsx-runtime';
import { config } from '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import { prepareHeaders } from '../auth/prepareHeaders.js';

const ENDPOINT = 'DisposalDetails';
const disposalDetailsApi = createApi({
    reducerPath: 'disposalDetailsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.API_BASE_URL,
        prepareHeaders,
    }),
    tagTypes: ['DisposalDetails'],
    endpoints: (builder) => ({
        getDisposalRequestAllExceptions: builder.query({
            query: ({ requestId }) => {
                return {
                    url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (RetentionConflict eq true)&$select=ID&$count=true`,
                    method: 'GET',
                };
            },
            providesTags: ['DisposalDetails'],
        }),
        getDisposalRequestRetentionConflictExceptions: builder.query({
            query: ({ requestId }) => {
                return {
                    url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (RetentionConflict eq true)&$select=ID&$top=1&$count=true`,
                    method: 'GET',
                };
            },
            providesTags: ['DisposalDetails'],
        }),
        getDisposalRequestRejectedInOtherRequestExceptions: builder.query({
            query: ({ requestId }) => {
                return {
                    url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (RejectedInOtherRequest eq true)&$select=ID&$top=1&$count=true`,
                    method: 'GET',
                };
            },
            providesTags: ['DisposalDetails'],
        }),
        getItemExceptions: builder.query({
            query: ({ itemId }) => {
                return {
                    url: `${ENDPOINT}?$filter=ItemId eq '${itemId}'&$select=DisposalRequestId,IsDeferred,FailureDetail,RetentionConflict,RejectedInOtherRequest,RelatedItemRetentionConflict,RelatedItemRejectedInOtherRequest`,
                    method: 'GET',
                };
            },
            providesTags: ['DisposalDetails'],
        }),
    }),
});
const { useGetDisposalRequestRetentionConflictExceptionsQuery, useGetDisposalRequestAllExceptionsQuery, useGetDisposalRequestRejectedInOtherRequestExceptionsQuery, useGetItemExceptionsQuery } = disposalDetailsApi;

export { disposalDetailsApi, useGetDisposalRequestAllExceptionsQuery, useGetDisposalRequestRejectedInOtherRequestExceptionsQuery, useGetDisposalRequestRetentionConflictExceptionsQuery, useGetItemExceptionsQuery };
