const MsalAccountToUser = (account) => {
    return {
        localId: account?.localAccountId,
        name: account?.name,
        username: account?.username,
        initials: getInitials(account?.name),
    };
};
const MsalAccountToAnalyticsUser = (account) => {
    return {
        localId: account?.localAccountId,
        name: account?.name,
        username: account?.username,
        initials: getInitials(account?.name),
    };
};
const getInitials = (name) => {
    let nameInitials = name
        ?.toUpperCase()
        .split(' ')
        .map((n) => n.substring(0, 1))
        .join('')
        .replace(/[^A-Z]/g, '') ?? "";
    if (nameInitials.length >= 2)
        nameInitials = nameInitials[0] + nameInitials[1];
    else if (nameInitials.length === 1)
        nameInitials = nameInitials[0];
    return nameInitials;
};

export { MsalAccountToAnalyticsUser, MsalAccountToUser, getInitials };
