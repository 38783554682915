export { loginRequest, msalConfiguration } from './auth/config.js';
export { AuthErrorComponent, RedirectOnError, RedirectOnLogout } from './auth/AuthComponents.js';
export { AuthProvider, AuthProviderBasic, AuthProviderWithLogoutComponent, AuthenticatedChildren, UnauthenticatedChildren, publicClientApplication } from './auth/AuthProvider.js';
export { useLogin, useMsalAnalyticsUser, useMsalLogout, useMsalUser } from './auth/hooks.js';
export { prepareHeaders } from './auth/prepareHeaders.js';
export { Alert } from './components/alert/Alert.js';
export { DisposalRequestSuccessAlert, DisposalRequestWarningAlert } from './components/alert/DisposalRequestAlerts.js';
export { DisposalRequestStatusAlert, getMessageForStatus } from './components/alert/DisposalRequestStatusAlert.js';
export { Button } from './components/button/Button.js';
export { AboutButton, CloseButton, CollapseButton, DeleteButton, EditButton, GridButton, HomeButton, MenuButton, NextButton, ParentNavigationButton, PreviousButton, RefreshButton, ShapedCloseButton } from './components/button/CustomButton.js';
export { SizedIconButton } from './components/button/SizedIconButton.js';
export { TextIconButton } from './components/button/TextIconButton.js';
export { Chip } from './components/chip/Chip.js';
export { DisposalRequestResponseStatusChip } from './components/chip/DisposalRequestResponseStatusChip.js';
export { DisposalRequestStatusChip } from './components/chip/DisposalRequestStatusChip.js';
export { DisposalRequestStatusIndicator } from './components/chip/DisposalRequestStatusIndicator.js';
export { Indicator } from './components/chip/Indicator.js';
export { ItemDisposalStatusChip } from './components/chip/ItemDisposalStatusChip.js';
export { ItemManagementStatusChip, MemoizedItemManagementStatusChip } from './components/chip/ItemManagementStatusChip.js';
export { PerspectiveClassChip } from './components/chip/PerspectiveClassChip.js';
export { RequestStatusChip } from './components/chip/RequestStatusChip.js';
export { RetentionClassChip } from './components/chip/RetentionClassChip.js';
export { UserChip } from './components/chip/UserChip.js';
export { AboutDialog } from './components/dialog/AboutDialog.js';
export { ChangeApproversDialog } from './components/dialog/ChangeApproversDialog.js';
export { ConfirmDialog } from './components/dialog/ConfirmDialog.js';
export { CustomActionModal } from './components/dialog/CustomActionModal.js';
export { DetailDialog } from './components/dialog/DetailDialog.js';
export { DetailTabDialog } from './components/dialog/DetailTabDialog.js';
export { DisposalApprovalDialog } from './components/dialog/DisposalApprovalDialog.js';
export { DisposalRequestRemovalDialog } from './components/dialog/DisposalRequestRemovalDialog.js';
export { DisposalRequestApprovalDialog } from './components/dialog/DisposalRequestApprovalDialog.js';
export { DisposalRequestDelegationDialog } from './components/dialog/DisposalRequestDelegationDialog.js';
export { DisposalRequestRejectionDialog } from './components/dialog/DisposalRequestRejectionDialog.js';
export { EditBooleanDialog } from './components/dialog/EditBooleanDialog.js';
export { EditBusinessTypeDialog } from './components/dialog/EditBusinessTypeDialog.js';
export { EditCustomTypeDialog } from './components/dialog/EditCustomTypeDialog.js';
export { EditDateDialog } from './components/dialog/EditDateDialog.js';
export { EditDateTimeDialog } from './components/dialog/EditDateTimeDialog.js';
export { EditEnumDialog } from './components/dialog/EditEnumDialog.js';
export { EditNumberDialog } from './components/dialog/EditNumberDialog.js';
export { EditPerspectiveClassDialog } from './components/dialog/EditPerspectiveClassDialog.js';
export { EditPerspectiveClassesDialog } from './components/dialog/EditPerspectiveClassesDialog.js';
export { EditRetentionClassesDialog } from './components/dialog/EditRetentionClassesDialog.js';
export { EditCategoriesDialog } from './components/dialog/EditCategoriesDialog.js';
export { EditTextDialog } from './components/dialog/EditTextDialog.js';
export { FullModalDialog } from './components/dialog/FullModalDialog.js';
export { InformationDialog } from './components/dialog/InformationDialog.js';
export { MemoizedPageSizeDropdown, PageSizeDropdown } from './components/grid/components/PageSizeDropdown.js';
export { MemoizedPageSelector, PageSelector } from './components/grid/components/PageSelector.js';
export { ImageWithFallback } from './components/grid/components/ImageWithFallback.js';
export { OpenInContextMenu } from './components/grid/components/OpenInContextMenu.js';
export { LinkWithArrow } from './components/grid/components/LinkWithArrow.js';
export { Filter } from './components/grid/components/filter/Filter.js';
export { DisposalRequestGrid } from './components/grid/DisposalRequestGrid.js';
export { ItemsGrid } from './components/grid/ItemsGrid.js';
export { CommentTemplate, MemoizedCommentTemplate } from './components/grid/templates/CommentTemplate.js';
export { ItemSummaryTemplate, MemoizedItemSummaryTemplate } from './components/grid/templates/ItemSummaryTemplate.js';
export { LinkTemplate } from './components/grid/templates/LinkTemplate.js';
export { MemoizedPagerTemplate, PagerTemplate } from './components/grid/templates/PagerTemplate.js';
export { ResponseCommentsTemplate } from './components/grid/templates/ResponseCommentsTemplate.js';
export { MemoizedRetentionClassTemplate, RetentionClassTemplate } from './components/grid/templates/RetentionClassTemplate.js';
export { MemoizedSummaryTemplate, SummaryTemplate } from './components/grid/templates/SummaryTemplate.js';
export { DisposalStatusTemplate, MemoizedDisposalStatusTemplate } from './components/grid/templates/DisposalStatusTemplate.js';
export { ItemBusinessTypePanel } from './components/item/ItemBusinessTypePanel.js';
export { ItemEmptyState } from './components/item/ItemEmptyState.js';
export { Dialog, ItemGovernancePanel } from './components/item/ItemGovernancePanel.js';
export { ItemTypePanel } from './components/item/ItemTypePanel.js';
export { ItemTypeConversationPanel } from './components/item/type/ItemTypeConversationPanel.js';
export { ItemTypeEmailPanel } from './components/item/type/ItemTypeEmailPanel.js';
export { ItemTypeEventPanel } from './components/item/type/ItemTypeEventPanel.js';
export { ItemTypeImagePanel } from './components/item/type/ItemTypeImagePanel.js';
export { ItemTypeItemCollectionPanel } from './components/item/type/ItemTypeItemCollectionPanel.js';
export { ItemTypeItemPanel } from './components/item/type/ItemTypeItemPanel.js';
export { ItemTypeTaskPanel } from './components/item/type/ItemTypeTaskPanel.js';
export { ItemTypeVideoPanel } from './components/item/type/ItemTypeVideoPanel.js';
export { SortableItem } from './components/list/SortableItem.js';
export { CommunityPage } from './components/page/CommunityPage.js';
export { ItemPanelPage } from './components/page/ItemPanelPage.js';
export { Page } from './components/page/Page.js';
export { ProtectedRoute } from './components/page/ProtectedRoute.js';
export { AppBar, AppBarWithActions } from './components/AppBar.js';
export { ApplicationButton } from './components/ApplicationButton.js';
export { ApplicationPanel } from './components/ApplicationPanel.js';
export { AppLogo } from './components/AppLogo.js';
export { Banner } from './components/Banner.js';
export { BannerNotice } from './components/BannerNotice.js';
import 'react/jsx-runtime';
import '@mui/material';
import '@mui/material/Breadcrumbs';
import '@mui/material/Menu';
import '@mui/material/MenuItem';
import 'react';
export { lightTheme, palette, themeLightOptions } from './themes/light.theme.js';
export { sizes } from './themes/sizes.js';
export { ECSThemeProvider } from './themes/Provider.js';
export { Tooltip } from './components/Tooltip.js';
import '@fluentui/react-icons';
export { Box } from './components/Box.js';
export { CommentText } from './components/CommentText.js';
export { CopyToClipboardButton } from './components/CopyToClipboardButton.js';
export { DetailsRow } from './components/DetailsRow.js';
export { DisposalDetailsPanel } from './components/DisposalDetailsPanel.js';
export { DisposalRequestApprovers, DisposalRequestApproversPanel } from './components/DisposalRequestApprovers.js';
export { DisposalRequestDetailsPanel } from './components/DisposalRequestDetailsPanel.js';
export { DisposalRequestHeaderActionButtons } from './components/DisposalRequestHeaderActionButtons.js';
export { DisposalRequestProgressIndicator } from './components/DisposalRequestProgressIndicator.js';
export { DisposalRequestProgressIndicatorStatus, DisposalRequestProgressStatus } from './components/DisposalRequestProgressIndicatorStatus.js';
export { DisposalRequestResponses } from './components/DisposalRequestResponses.js';
export { DisposalRequestResponseStatusText } from './components/DisposalRequestResponseStatusText.js';
export { EditApprovers } from './components/EditApprovers.js';
export { FileViewer, MemoizedFileViewer } from './components/FileViewer.js';
export { FilterDropdown } from './components/FilterDropdown.js';
export { InfoPanel } from './components/InfoPanel.js';
export { ItemNavigationBar } from './components/ItemNavigationBar.js';
export { ItemPanel, MemoizedItemPanel } from './components/ItemPanel.js';
export { ItemPropertiesPanel, MemoizedItemPropertiesPanel } from './components/ItemPropertiesPanel.js';
export { ItemSummaryPanel } from './components/ItemSummaryPanel.js';
export { ItemSummaryTitle } from './components/ItemSummaryTitle.js';
export { MultilineTypography } from './components/MultilineTypography.js';
export { SearchField } from './components/SearchField.js';
export { SelectApprover } from './components/SelectApprover.js';
export { SideMenu } from './components/SideMenu.js';
export { TabPanel } from './components/TabPanel.js';
export { Typography } from './components/Typography.js';
export { UserTile } from './components/UserTile.js';
export { ZenDeskSupport } from './components/ZenDeskSupport.js';
export { OptionSelect } from './components/OptionSelect.js';
export { SortableOptionList, StyledImage, Type } from './components/SortableOptionList.js';
export { OptionMultiSelect } from './components/OptionMultiSelect.js';
export { CustomTypeTreeGrid, MemoizedCustomTypeTreeGrid } from './components/CustomTypeTreeGrid.js';
export { TEST_CONSTANTS } from './constants/testConstants.js';
export { ALERT_TEXTS } from './constants/alert.js';
export { CaptureContentMessage, DocumentPreviewMessage, OpenItemMessage } from './constants/messages.js';
export { useAlert } from './hooks/useAlert.js';
export { useApprovers } from './hooks/useApprovers.js';
export { useApiAlert } from './hooks/useApiAlert.js';
export { APRYSE_WEBVIEWER_SUPPORTED_FILES, APRYSE_WEBVIEWER_SUPPORTED_MEDIA, useFileDetails } from './hooks/useFileDetails.js';
export { useBusinessType } from './hooks/useBusinessType.js';
export { useDisposalRequest } from './hooks/useDisposalRequest.js';
export { useItemDetails } from './hooks/useItemDetails.js';
export { useIMCUser, useUser } from './hooks/useUser.js';
export { useIcon } from './hooks/useIcon.js';
export { getIcon } from './hooks/hookUtils.js';
export { useBusinessTypePropertiesPanel } from './hooks/useBusinessTypePropertiesPanel.js';
export { useItemPropertiesMenuItem } from './hooks/useItemPropertiesMenuItem.js';
export { useTooltip } from './hooks/useTooltip.js';
export { useGetUserServiceAccess } from './hooks/useUserServiceAccess.js';
export { useItemPanel } from './hooks/useItemPanel.js';
export { useOptionMultiSelect } from './hooks/useOptionMultiSelect.js';
export { useDownloadFile } from './hooks/useDownloadFile.js';
export { useDisposalDetailsExceptions, useDisposalRequestExceptions, useItemExceptions } from './hooks/useDisposalDetails.js';
export { useItemGridFilterCount } from './hooks/useItemGridFilterCount.js';
export { useItemImage } from './hooks/useItemImage.js';
export { default as CloseRequest20 } from './icons/CloseRequest20.js';
export { default as CloseRequest24 } from './icons/CloseRequest24.js';
export { default as CollapseLeft24 } from './icons/CollapseLeft24.js';
export { default as DestructionActioned24 } from './icons/DestructionActioned24.js';
export { default as DestructionComplete24 } from './icons/DestructionComplete24.js';
export { default as Discovered24 } from './icons/Discovered24.js';
export { default as Disposal } from './icons/Disposal.js';
export { default as DisposalApproval } from './icons/DisposalApproval.js';
export { default as DisposalRequest64 } from './icons/DisposalRequest64.js';
export { default as Disposed24 } from './icons/Disposed24.js';
export { default as ExpandLeft24 } from './icons/ExpandLeft24.js';
export { default as History24 } from './icons/History24.js';
export { default as Imc } from './icons/Imc.js';
export { default as Managed24 } from './icons/Managed24.js';
export { default as ProgressIndicatorArrow40 } from './icons/ProgressIndicatorArrow40.js';
export { default as ReadyToDestroy24 } from './icons/ReadyToDestroy24.js';
export { default as RetentionClass24 } from './icons/RetentionClass24.js';
export { default as Analytics } from './icons/Analytics.js';
export { default as AnalyticsAdmin } from './icons/AnalyticsAdmin.js';
export { default as SearchApp } from './icons/SearchApp.js';
export { default as EncompaasBanner } from './images/EncompaasBanner.js';
export { default as EncompaasLogo } from './images/EncompaasLogo.js';
export { requestApi, useAppendNotesMutation, useApproveDisposalRequestMutation, useCloseDisposalMutation, useDelegateDisposalRequestMutation, useGetApproversListQuery, useGetDestroyedItemsCountQuery, useGetDestructionInProgressItemsCountQuery, useGetFailedToDestroyItemsCountQuery, useGetRequestCountsByApprovalStatusQuery, useGetRequestCountsByStatusQuery, useGetRequestDetailsQuery, useGetRequestItemsQuery, useGetRequestsByApprovalStatusQuery, useGetRequestsByStatusQuery, useLazyGetApproversListWithParamsQuery, useRejectDisposalRequestMutation, useStartDisposalJobMutation, useUpdateDisposalRequestApproversMutation, useUpdateDisposalRequestMutation, useUpdateDisposalRequestPropsMutation } from './services/requestApi.js';
export { useGetAnalyticsUserQuery, useGetCurrentUserQuery, useGetUserQuery, useGetUserServiceAccessQuery, userApi } from './services/userApi.js';
export { itemApi, useGetAllExceptionsItemCountQuery, useGetBusinessCatalogNodeQuery, useGetBusinessTypePropertiesPanelValuesQuery, useGetBusinessTypePropertiesQuery, useGetBusinessTypePropertyValuesQuery, useGetBusinessTypesQuery, useGetClassificationsQuery, useGetCollectionItemsCountQuery, useGetDeferredItemCountQuery, useGetEmailCopyQuery, useGetEnumsQuery, useGetFailureDetailQuery, useGetFileExtensionQuery, useGetIconByTypeDefIdQuery, useGetIconQuery, useGetItemCategoriesQuery, useGetItemCountQuery, useGetItemDetailsApplicableClassificationsQuery, useGetItemDetailsFullDetailsQuery, useGetItemDetailsMemberOfQuery, useGetItemDisposalDetailsQuery, useGetItemImageQuery, useGetItemTypePropertiesQuery, useGetPerspectiveClassQuery, useGetPerspectiveClassesQuery, useGetPropLinksQuery, useGetRejectedInOtherRequestItemCountQuery, useGetRetentionConflictItemCountQuery, useGetSnapshotCountQuery, useGetSnapshotMemberCountQuery, useGetTreeGridDataQuery, useLazyGetMostRecentSnapshotQuery, useLazyGetMultipleItemDetailsQuery, useLazyGetSnapshotMemberCountQuery, useRemoveItemsFromDisposalRequestMutation, useSaveItemDetailsMutation } from './services/itemApi.js';
export { disposalDetailsApi, useGetDisposalRequestAllExceptionsQuery, useGetDisposalRequestRejectedInOtherRequestExceptionsQuery, useGetDisposalRequestRetentionConflictExceptionsQuery, useGetItemExceptionsQuery } from './services/requestDetailsApi.js';
export { persistor, setupStore, store, useAppDispatch, useAppSelector } from './store/store.js';
export { StoreProvider } from './store/StoreProvider.js';
export { applicationSlice, initialState, reset, setSelectedSideMenuItemValue, setSideMenuCollapsed, setSideMenuGroupCollapsedState } from './store/slices/applicationSlice.js';
import './store/slices/downloadFileSlice.js';
export { itemInitialState, itemModalSlice, setCurrentItem, setSelectedSnapshot, setSnapshotMembers, updateItem } from './store/slices/itemModalSlice.js';
export { MsalAccountToAnalyticsUser, MsalAccountToUser, getInitials } from './types/userTypes.js';
export { DisposalRequestAction, DisposalRequestActionCaption, DisposalRequestActionDoingCaption, DisposalRequestActionDoneCaption, DisposalRequestResponseStatus, DisposalRequestResponseStatusCaption, DisposalRequestStatus, DisposalRequestStatusCaption } from './types/requestTypes.js';
export { DestroyItemsStatusCaption, ITEM_TYPE, ItemDisposalStatus, ItemDisposalStatusCaption, ItemGridQuery, ItemManagementStatus } from './types/itemTypes.js';
export { appLogos, getLogoForApp } from './util/appLogos.js';
export { convertObjectToArray, insertIf } from './util/array-utils.js';
export { acquireToken, getExceptionLabel, getImage, isUserInPending } from './util/data-utils.js';
export { formatCustomTypeDate, formatDate, formatDestructionDate, getFormattedDateTime, padSingleDigitWithZero } from './util/dateTime.js';
export { renderWithProviders } from './util/test-utils.js';
export { formatDisplayName, getAuthorisedApps, groupUserAppsByPurpose } from './util/userapps.js';
export { handleAction } from './util/request-page-handlers.js';
import { config } from './config.js';
import { registerLicense } from '@syncfusion/ej2-base';

// Registers syncfusion components with the encompaas license
registerLicense(config.SYNCFUSION_LICENSE || 'unlicensed');

export { config };
