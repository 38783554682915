import { jsx } from 'react/jsx-runtime';
import { ChevronDownFilled } from '@fluentui/react-icons';
import { Select, OutlinedInput, MenuItem, Typography } from '@mui/material';
import '../../../../services/requestApi.js';
import '../../../../services/userApi.js';
import { useGetEnumsQuery } from '../../../../services/itemApi.js';
import '../../../../services/requestDetailsApi.js';
import { Box } from '../../../Box.js';
import { getEnumValues } from './customTypeUtils.js';

const EnumField = ({ value, onChange, propertyDetails, }) => {
    const { data: enums } = useGetEnumsQuery({});
    const propTypeDefId = propertyDetails?.PropTypeDefId;
    const enumValues = getEnumValues(enums, propTypeDefId);
    return !!enums ? (jsx(Box, { background: 'none', children: jsx(Select, { value: value, onChange: onChange, variant: 'outlined', input: jsx(OutlinedInput, { notched: false }), IconComponent: ChevronDownFilled, sx: {
                width: '100%',
                height: '100%',
                maxWidth: '10.875rem',
                maxHeight: '3rem',
                paddingLeft: '0.75rem',
            }, MenuProps: { classes: { paper: 'filter-dropdown' } }, children: enumValues?.map((enumValue, index) => (jsx(MenuItem, { value: enumValue.name, children: jsx(Typography, { variant: 'body2', children: enumValue.Caption }) }, index))) }) })) : null;
};

export { EnumField };
