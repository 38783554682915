import { createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config.js';
import 'react/jsx-runtime';
import { acquireToken } from '../../util/data-utils.js';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '@mui/material';
import '../../themes/light.theme.js';
import '../store.js';
import 'redux-persist/integration/react';
import '../slices/applicationSlice.js';
import '../slices/downloadFileSlice.js';
import '../slices/itemModalSlice.js';

const downloadFileThunk = createAsyncThunk('downloadFile/fetch', async ({ id, version }) => {
    const token = await acquireToken();
    const API_URL = `${config.API_BASE_URL}/Item('${id}')/Download?ds=5`;
    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            Authorization: `bearer ${token}`,
        },
        redirect: 'follow',
        ...version && ({
            body: JSON.stringify({
                "forVersionId": version
            })
        })
    });
    if (!response.ok) {
        throw new Error('Failed to download');
    }
    return response.blob();
});

export { downloadFileThunk };
