import { useAppDispatch } from '../store/store.js';
import 'react/jsx-runtime';
import 'react-redux';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import { downloadFileThunk } from '../store/thunks/downloadFileThunk.js';

const useDownloadFile = () => {
    const dispatch = useAppDispatch();
    const downloadFile = async ({ id, version, itemDetails, }) => {
        try {
            const blobAction = await dispatchFile(dispatch, downloadFileThunk, id, version);
            if (isDownloadFulfilled(blobAction)) {
                const blob = blobAction.payload;
                initiateDownload(blob, itemDetails);
            }
        }
        catch (error) {
            console.error('Error downloading the file:', error);
        }
    };
    return { downloadFile };
};
const dispatchFile = async (dispatch, thunk, id, version) => {
    return await dispatch(thunk({ id, version }));
};
const isDownloadFulfilled = (blobAction) => {
    return downloadFileThunk.fulfilled.match(blobAction);
};
const initiateDownload = (blob, itemDetails) => {
    const filename = generateFilename(itemDetails);
    const blobURL = window.URL.createObjectURL(blob);
    triggerDownload(blobURL, filename);
    window.URL.revokeObjectURL(blobURL);
};
const generateFilename = (itemDetails) => {
    const fileExtension = itemDetails?.StorageObject?.FileExtension ?? itemDetails?.Body?.MimeType;
    return `${itemDetails._Display}.${fileExtension?.toLowerCase()}`;
};
const triggerDownload = (blobURL, filename) => {
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = filename;
    link.click();
};

export { useDownloadFile };
