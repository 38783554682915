import { createSlice } from '@reduxjs/toolkit';

const itemInitialState = {
    navigation: {
        levels: [],
    },
    selectedSnapshot: undefined
};
const itemModalSlice = createSlice({
    name: 'itemModal',
    initialState: itemInitialState,
    reducers: {
        setCurrentItem: (state, action) => {
            if (action.payload == undefined) {
                state.navigation.levels = [];
            }
            else {
                if (action.payload.index !== undefined && action.payload.index > -1 && !action.payload.shouldExpand) {
                    const foundNavigatedIndex = state.navigation.levels.findIndex((x) => x.id == action.payload.id && x.index == action.payload.index);
                    const shrinkBreadcrumb = state.navigation.levels.splice(0, action.payload?.index + 1);
                    state.navigation.levels = shrinkBreadcrumb;
                    state.navigation.levels[foundNavigatedIndex] = action.payload;
                }
                else {
                    const expandBreadcrumb = [...state.navigation.levels, { ...action.payload, index: state.navigation.levels.length }];
                    state.navigation.levels = expandBreadcrumb;
                }
            }
        },
        updateItem: (state, action) => {
            const foundNavigatedIndex = state.navigation.levels.findIndex((x) => x.index == action.payload.index);
            state.navigation.levels[foundNavigatedIndex] = action.payload;
        },
        setSelectedSnapshot: (state, action) => {
            if (action.payload == undefined) {
                state.selectedSnapshot = undefined;
            }
            else {
                state.selectedSnapshot = action.payload;
            }
        },
        setSnapshotMembers: (state, action) => {
            if (action.payload == undefined) {
                state.snapshotMembers = undefined;
            }
            else {
                state.snapshotMembers = action.payload;
            }
        }
    },
});
const { setCurrentItem, setSelectedSnapshot, setSnapshotMembers, updateItem } = itemModalSlice.actions;
var itemModalReducer = itemModalSlice.reducer;

export { itemModalReducer as default, itemInitialState, itemModalSlice, setCurrentItem, setSelectedSnapshot, setSnapshotMembers, updateItem };
