import { jsxs, jsx } from 'react/jsx-runtime';
import { TabContext, TabPanel as TabPanel$1 } from '@mui/lab';
import { Tabs, Tab, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import { Box } from './Box.js';

const TabPanel = ({ selected, tabs = [], inset = 0, divider = true, onSelect, }) => {
    const [_selected, setSelected] = useState(tabs?.[0]?.value);
    //selected can be controlled by parent
    useEffect(() => {
        setSelected(selected ?? tabs?.[0]?.value);
    }, [selected, tabs]);
    const handleSelect = (value) => {
        setSelected(value);
        onSelect && onSelect(value);
    };
    return (jsxs(Box, { background: 'none', children: [jsx(Box, { background: 'none', height: 2, justifyContent: 'end', style: { paddingLeft: inset }, children: jsx(Tabs, { value: _selected ?? false, onChange: (e, value) => handleSelect(value), children: tabs?.map((tab, index) => (jsx(Tab, { label: tab.title ?? tab.value, value: tab.value, sx: { display: (tab?.isVisible ?? true) ? 'inline-flex' : 'none' }, disabled: tab?.disabled }, index))) }) }), divider && jsx(Divider, { style: { width: '100%' } }), jsx(Box, { background: 'light', children: jsx(TabContext, { value: _selected ?? '', children: tabs?.map((tab, index) => (jsx(TabPanel$1, { value: tab.value, sx: {
                            padding: 0,
                            overflow: 'hidden',
                            height: '100%',
                        }, children: tab.children }, index))) }) })] }));
};

export { TabPanel };
