import dayjs from 'dayjs';

//
const formatDate = (isoDateString, isDateTime = true) => {
    if (!isoDateString)
        return null;
    const dateObject = new Date(isoDateString);
    const twoDigitDay = padSingleDigitWithZero(dateObject.getDate());
    const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1);
    const fourDigitYear = dateObject.getFullYear();
    return !isDateTime ? `${twoDigitMonth}/${twoDigitDay}/${fourDigitYear}` : dateObject.toLocaleString().replace(',', '');
};
const formatCustomTypeDate = (isoDateString, isDateTime = true) => {
    const dateObject = new Date(isoDateString);
    const twoDigitDay = padSingleDigitWithZero(dateObject.getDate()).toLocaleString();
    const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1).toLocaleString();
    const fourDigitYear = dateObject.getFullYear();
    return isDateTime ? `${fourDigitYear}-${twoDigitMonth}-${twoDigitDay}T00:00:00` : `${fourDigitYear}-${twoDigitMonth}-${twoDigitDay}`;
};
const padSingleDigitWithZero = (number) => {
    return number < 10 ? `0${number}` : `${number}`;
};
const getFormattedDateTime = (dateTimeStr) => {
    if (!dateTimeStr)
        return null;
    return dayjs(dateTimeStr).format("MM/DD/YYYY HH:mm");
};
const formatDestructionDate = (isoDateString) => {
    const dateObject = new Date(isoDateString);
    const twoDigitDay = padSingleDigitWithZero(dateObject.getDate()).toLocaleString();
    const monthString = dateObject.toLocaleString('default', { month: 'short' }).toLocaleString();
    const fourDigitYear = dateObject.getFullYear();
    return `${twoDigitDay} ${monthString} ${fourDigitYear}`;
};

export { formatCustomTypeDate, formatDate, formatDestructionDate, getFormattedDateTime, padSingleDigitWithZero };
