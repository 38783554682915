import { jsxs, jsx } from 'react/jsx-runtime';
import { styled, MenuItem, Typography, Tooltip as Tooltip$1, Menu, IconButton } from '@mui/material';
import React__default from 'react';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import { palette } from '../themes/light.theme.js';
import { useAppSelector } from '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import { useTooltip } from '../hooks/useTooltip.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { Box } from './Box.js';
import { Tooltip } from './Tooltip.js';
import { MenuButton } from './button/CustomButton.js';
import { Indicator } from './chip/Indicator.js';
import { Camera28Filled, ArrowDownload20Regular, Link20Regular } from '@fluentui/react-icons';

// TODO: MenuItem Component need to be refactored out of here
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: 0,
    paddingRight: '3rem',
    width: '100%',
    '&:hover': {
        color: '#ffffff',
        '& .menu-icon': {
            color: '#ffffff',
        },
    },
}));
const ItemSummaryTitle = ({ icon, displayName, businessType, itemType, isMoreButtonAvailable = false, onDownloadClick, onCopyLinkClick, }) => {
    const { dataRef, title } = useTooltip(displayName);
    const levels = useAppSelector((state) => state.itemModal.navigation.levels);
    const [anchorEl, setAnchorEl] = React__default.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (jsxs(Box, { background: 'none', direction: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 'medium', height: levels?.[levels?.length - 1]?.isSnapshot ? 6 : 4.5, style: { padding: '0rem 1.5rem' }, children: [icon && !levels?.[levels?.length - 1]?.isSnapshot && (jsx("div", { children: icon })), levels?.[levels?.length - 1]?.isSnapshot && (jsx("div", { style: { height: '3.375rem', display: 'flex' }, children: jsx("div", { style: { alignSelf: 'center' }, children: jsx(Camera28Filled, { color: palette.aqua[800] }) }) })), jsxs("div", { style: { display: 'flex', flexDirection: 'column' }, children: [jsxs("div", { style: { display: 'flex', flexDirection: 'row' }, children: [jsx(Tooltip, { title: title, arrow: true, children: jsx(Typography, { variant: 'h1', style: {
                                        margin: '-0.25rem',
                                        maxWidth: '37.5rem',
                                        marginRight: '1rem'
                                    }, ref: dataRef, children: displayName }) }), businessType && (jsx(Indicator, { label: businessType, sx: {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    marginRight: '0.5rem'
                                } })), itemType && jsx(Indicator, { label: itemType })] }), levels?.[levels?.length - 1]?.isSnapshot && (jsx("div", { style: { marginTop: '0.5rem' }, children: jsx(Typography, { variant: 'h4', color: 'primary', children: levels?.[levels?.length - 1]?.name }) }))] }), jsx("div", { style: { flexGrow: 1 } }), jsx(Tooltip$1, { title: '', children: jsx(MenuButton, { onClick: handleClick, "aria-controls": open ? 'account-menu' : undefined, "aria-haspopup": 'true', "aria-expanded": open ? 'true' : undefined, size: 'small', color: 'primary' }, 'small') }), jsxs(Menu, { anchorEl: anchorEl, id: 'account-menu', open: open, onClose: handleClose, onClick: handleClose, slotProps: {
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }, transformOrigin: { horizontal: 'right', vertical: 'top' }, anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, children: [isMoreButtonAvailable && (jsxs(StyledMenuItem, { onClick: () => {
                            onDownloadClick && onDownloadClick();
                        }, children: [jsx(IconButton, { className: 'menu-icon', color: 'primary', size: 'large', style: { padding: 0 }, children: jsx(ArrowDownload20Regular, {}) }), jsx(Typography, { variant: 'body2', color: 'inherit', noWrap: true, fontSize: 'inherit', style: { flexGrow: 1 }, textTransform: 'none', children: "Download content" })] })), jsxs(StyledMenuItem, { onClick: () => {
                            onCopyLinkClick && onCopyLinkClick();
                        }, children: [jsx(IconButton, { className: 'menu-icon', color: 'primary', size: 'large', style: { padding: 0 }, children: jsx(Link20Regular, {}) }), jsx(Typography, { variant: 'body2', color: 'inherit', noWrap: true, fontSize: 'inherit', textTransform: 'none', style: { flexGrow: 1 }, children: "Copy link" })] })] })] }));
};

export { ItemSummaryTitle };
