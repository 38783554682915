import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useTheme, Link, Typography } from '@mui/material';
import '../../../auth/config.js';
import '../../../config.js';
import '../../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react';
import '../../../types/requestTypes.js';
import '../../../types/itemTypes.js';
import { getFormattedDateTime } from '../../../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../../../themes/light.theme.js';
import '../../../store/store.js';
import 'redux-persist/integration/react';
import '../../../store/slices/applicationSlice.js';
import '../../../store/slices/downloadFileSlice.js';
import '../../../store/slices/itemModalSlice.js';
import { DetailsRow } from '../../DetailsRow.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../../constants/messages.js';
import '../../../services/requestApi.js';
import '../../../services/userApi.js';
import '../../../services/itemApi.js';
import '../../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import { useTooltip } from '../../../hooks/useTooltip.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../../store/thunks/downloadFileThunk.js';
import { Tooltip } from '../../Tooltip.js';
import '@fluentui/react-icons';
import '../../chip/ItemManagementStatusChip.js';
import '../../chip/PerspectiveClassChip.js';
import { UserChip } from '../../chip/UserChip.js';

const ItemTypeTaskPanel = ({ item }) => {
    const itemType = item?.TypeDef?._Display;
    const theme = useTheme();
    const repositoryUrl = item?.RepositoryUrl;
    const { dataRef, title: tooltipTitle } = useTooltip(repositoryUrl);
    // TODO: Refactor this component, make the property names dynamic.
    return (jsxs(Fragment, { children: [jsx(DetailsRow, { label: 'Name', data: item?.DisplayName }, `${itemType}-name`), jsx(DetailsRow, { label: 'Body', data: '' }, `${itemType}-body`), jsx(DetailsRow, { label: 'Task Status', data: '' }, `${itemType}-task-status`), jsx(DetailsRow, { label: 'Task Category', data: '' }, `${itemType}-task-category`), jsx(DetailsRow, { label: 'Priority', data: '' }, `${itemType}-priority`), jsx(DetailsRow, { label: 'Assigned To', data: '' }, `${itemType}-assigned-to`), jsx(DetailsRow, { label: 'Is Current', data: '' }, `${itemType}-is-current`), jsx(DetailsRow, { label: 'Start Date', data: '' }, `${itemType}-start-date`), jsx(DetailsRow, { label: 'Percentage Complete', data: '' }, `${itemType}-percentage-complete`), jsx(DetailsRow, { label: 'Checkmark', data: '' }, `${itemType}-checkmark`), jsx(DetailsRow, { label: 'Created By', data: item?.CreatedBy?.DisplayName ? (jsx(UserChip, { user: { name: item?.CreatedBy?.DisplayName } })) : null }, `${itemType}-created-by`), jsx(DetailsRow, { label: 'Date Created', data: getFormattedDateTime(item?.DateCreated) }, `${itemType}-date-created`), jsx(DetailsRow, { label: 'Date Modified', data: getFormattedDateTime(item?.DateModified) }, `${itemType}-date-modified`), jsx(DetailsRow, { label: 'Repository URL', data: jsx(Link, { href: item?.RepositoryUrl, target: '_blank', sx: { textDecoration: 'none' }, children: jsx(Tooltip, { title: tooltipTitle, arrow: true, children: jsx(Typography, { ref: dataRef, variant: 'body1', sx: {
                                color: theme.palette.primary.main,
                                textDecoration: 'none',
                                maxWidth: '29.313rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                wordBreak: 'break-all',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                            }, children: item?.RepositoryUrl }) }) }) }, `${itemType}-repository-url`)] }));
};

export { ItemTypeTaskPanel };
