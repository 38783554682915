import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { Typography } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Box } from '../../Box.js';
import { MemoizedPageSizeDropdown } from '../components/PageSizeDropdown.js';
import { MemoizedPageSelector } from '../components/PageSelector.js';
import '@fluentui/react-icons';
import '@mui/system';

const PagerTemplate = ({ pagerData, grid, pageSizes, compact = false, hidePages = false }) => {
    const [currPage, setCurrPage] = useState(pagerData?.currentPage ?? 1);
    const moveToPage = (page) => {
        // refresh is needed to get the updated data after the initial different page render
        grid.refresh();
        grid.pageSettings.currentPage = page;
        setCurrPage(page);
    };
    const changePageSize = (size) => {
        grid.pageSettings.pageSize = size;
    };
    const totalPages = pagerData.totalPages;
    const pageSize = pagerData.pageSize;
    const start = currPage * pageSize - pageSize + 1;
    const end = currPage === totalPages
        ? pagerData.totalRecordsCount ?? 0
        : currPage * pageSize;
    useEffect(() => {
        if (grid)
            grid.pageSettings.currentPage = currPage;
    }, [currPage]);
    return pagerData.totalRecordsCount == 0 ? (jsx(Fragment, {})) : (jsxs(Box, { background: 'none', direction: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 'large', height: compact ? 1 : 3, style: { flex: 1 }, children: [jsxs(Typography, { variant: 'body1', children: ["Showing ", start, " - ", end, " of ", hidePages ? 'all results' : `${pagerData.totalRecordsCount} results`] }), jsx(MemoizedPageSelector, { currentPage: currPage, totalPages: totalPages, onSelect: moveToPage, hidePages: hidePages }), jsx(MemoizedPageSizeDropdown, { options: pageSizes, onChange: changePageSize, pageSize: pageSize })] }));
};
const MemoizedPagerTemplate = memo(PagerTemplate);

export { MemoizedPagerTemplate, PagerTemplate };
