var ItemManagementStatus;
(function (ItemManagementStatus) {
    ItemManagementStatus["None"] = "None";
    ItemManagementStatus["Managed"] = "Managed";
    ItemManagementStatus["Discovered"] = "Discovered";
    ItemManagementStatus["Disposed"] = "Disposed";
    ItemManagementStatus["Created"] = "Created";
    ItemManagementStatus["Destroyed"] = "Destroyed";
})(ItemManagementStatus || (ItemManagementStatus = {}));
//this enum may need more work to have the complete set of values
var ItemDisposalStatus;
(function (ItemDisposalStatus) {
    ItemDisposalStatus["Pending"] = "Pending";
    ItemDisposalStatus["Triggered"] = "Triggered";
    ItemDisposalStatus["AssignedToRequest"] = "AssignedToRequest";
    ItemDisposalStatus["DisposalApproved"] = "DisposalApproved";
    ItemDisposalStatus["DisposalRejected"] = "DisposalRejected";
    ItemDisposalStatus["ReadyToTransfer"] = "ReadyToTransfer";
    ItemDisposalStatus["ReadyToDestroy"] = "ReadyToDestroy";
    ItemDisposalStatus["DestructionInProgress"] = "DestructionInProgress";
    ItemDisposalStatus["Destroyed"] = "Destroyed";
    ItemDisposalStatus["Transferred"] = "Transferred";
    ItemDisposalStatus["DisposalFailed"] = "DisposalFailed";
    ItemDisposalStatus["DestructionActioned"] = "DestructionActioned";
})(ItemDisposalStatus || (ItemDisposalStatus = {}));
var DestroyItemsStatusCaption;
(function (DestroyItemsStatusCaption) {
    DestroyItemsStatusCaption["DestructionInProgress"] = "Destruction In Progress";
    DestroyItemsStatusCaption["DestructionActioned"] = "Destruction Actioned";
    DestroyItemsStatusCaption["DestructionFailed"] = "Failed To Destroy";
    DestroyItemsStatusCaption["Destroyed"] = "Destroyed";
})(DestroyItemsStatusCaption || (DestroyItemsStatusCaption = {}));
const ItemDisposalStatusCaption = {
    [ItemDisposalStatus.Pending]: 'Pending',
    [ItemDisposalStatus.Triggered]: 'Triggered',
    [ItemDisposalStatus.AssignedToRequest]: 'Assigned To Request',
    [ItemDisposalStatus.DisposalApproved]: 'Disposal Approved',
    [ItemDisposalStatus.DisposalRejected]: 'Disposal Rejected',
    [ItemDisposalStatus.ReadyToTransfer]: 'Ready To Transfer',
    [ItemDisposalStatus.ReadyToDestroy]: 'Ready To Destroy',
    [ItemDisposalStatus.DestructionInProgress]: 'Destruction In Progress',
    [ItemDisposalStatus.Destroyed]: 'Destroyed',
    [ItemDisposalStatus.Transferred]: 'Transferred',
    [ItemDisposalStatus.DisposalFailed]: 'Disposal Failed',
    [ItemDisposalStatus.DestructionActioned]: 'Destruction Actioned',
};
var ITEM_TYPE;
(function (ITEM_TYPE) {
    ITEM_TYPE["AUDIO"] = "Audio";
    ITEM_TYPE["CONVERSATION"] = "PostBasedConversation";
    ITEM_TYPE["DOCUMENT"] = "Document";
    ITEM_TYPE["EMAIL"] = "Email";
    ITEM_TYPE["EVENT"] = "EventType";
    ITEM_TYPE["IMAGE"] = "ImageType";
    ITEM_TYPE["ITEM_COLLECTION"] = "ItemCollection";
    ITEM_TYPE["ITEM"] = "Item";
    ITEM_TYPE["TASK"] = "TaskType";
    ITEM_TYPE["VIDEO"] = "Video";
    ITEM_TYPE["POST"] = "Post";
})(ITEM_TYPE || (ITEM_TYPE = {}));
/**
 * Enum representing different queries used for the ItemsGrid components
 * @enum {string}
 */
var ItemGridQuery;
(function (ItemGridQuery) {
    /**
     * Indicates that the ItemsGrid will display items from a collection
     */
    ItemGridQuery["Child"] = "Child";
    /**
     * Indicates that the ItemsGrid will display items from a collection and will only include items that have a status
     */
    ItemGridQuery["ChildWithStatus"] = "ChildWithStatus";
    /**
     * Indicates that the ItemsGrid will display items from a disposal request
     */
    ItemGridQuery["Root"] = "Root";
    /**
     * Indicates that the ItemsGrid will display items from a disposal request and will only include items that have a status
     */
    ItemGridQuery["RootWithStatus"] = "RootWithStatus";
    /**
     * Indicates that the ItemsGrid will display items from a destroy items and will include items and item collections that have a disposal states
     */
    ItemGridQuery["DestroyItems"] = "DestroyItems";
    /**
     * Indicates that the ItemsGrid will display items from a Snapshot History Member Details
     */
    ItemGridQuery["SnapshotMembers"] = "SnapshotMembers";
})(ItemGridQuery || (ItemGridQuery = {}));

export { DestroyItemsStatusCaption, ITEM_TYPE, ItemDisposalStatus, ItemDisposalStatusCaption, ItemGridQuery, ItemManagementStatus };
