const dateTimeToUTC = (dateTime) => {
    const date = new Date(dateTime);
    const handlePadStart = (value) => value.toString().padStart(2, '0');
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const formattedDate = `${handlePadStart(date.getUTCDate())} ${months[date.getUTCMonth()]} ${date.getUTCFullYear()}`;
    const formattedTime = `${handlePadStart(date.getUTCHours())}:${handlePadStart(date.getUTCMinutes())}:${handlePadStart(date.getUTCSeconds())}`;
    return `${formattedDate} ${formattedTime} UTC`;
};

export { dateTimeToUTC };
